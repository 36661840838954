<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item label="培训名称：">
					<el-autocomplete
						v-model.trim="formInline.name"
						:fetch-suggestions="querySearchAsync"
						placeholder="请输入内容"
						@select="handleSelect"
						clearable
					></el-autocomplete>
				</el-form-item>
				<el-form-item label="培训形式：">
					<el-select v-model="formInline.trainingFormCode" placeholder="请选择">
						<el-option label="全部" value=""></el-option>
						<el-option label="线上" value="U"></el-option>
						<el-option label="线下" value="D"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
				</template>
				<template #showMore="{ scope }">
					<el-button v-if="scope.row.traineeCount > 0" @click="onShow(scope.row)" type="text">查看</el-button>
					<span v-else>-</span>
				</template>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button
						@click="modifyParkInfo($event, false, scope.row)"
						size="mini"
						:type="scope.row.enabled ? 'info' : 'warning'"
						:disabled="scope.row.enabled"
					>
						修改
					</el-button>
				</template>
			</Table>
		</div>
		<createView ref="createView" @getList="getList" :isShowDetail="isShowDetail" />
		<ShowList ref="showList" />
	</div>
</template>

<script>
export default {
	name: 'trainingManagement',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
		ShowList: () => import('./showList.vue'),
	},
	data() {
		return {
			formInline: {
				name: '',
				trainingFormCode: '',
			},
			trainingFormCodeList: [],
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '培训名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '报名时间',
					prop: 'registerStartTime',
					width: 170,
					formatter: (row) => {
						return row.registerEndTime ? row.registerStartTime + '-' + row.registerEndTime : '-';
					},
				},
				{
					label: '培训开始时间',
					prop: 'startTime',
					width: 160,
					formatter: (row) => {
						return row.startTime || '-';
					},
				},
				{
					label: '培训形式',
					prop: 'trainingFormCode',
					formatter: (row) => {
						return row.trainingFormCode == 'U' ? '线上' : row.trainingFormCode == 'D' ? '线下' : '-';
					},
				},
				{
					label: '培训地点',
					prop: 'address',
					formatter: (row) => {
						return row.address || '-';
					},
				},
				{
					label: '培训人数',
					width: 80,
					prop: 'traineeCount',
					formatter: (row) => {
						return row.traineeCount || '-';
					},
				},
				{
					label: '查看人员',
					prop: 'showMore',
				},
				{
					label: '创建时间',
					prop: 'createTime',
					width: 160,
					formatter: (row) => {
						return row.createTime || '-';
					},
				},
				{
					label: '是否启用',
					prop: 'enabled',
					width: 110,
				},
			],
			options: [],
			filterLoading: false,
			isShowDetail: false,
			link: '',
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		handleSelect(subInput) {
			this.formInline.name = subInput.name;
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? '' : (this.loading = true);
			let data = {
				...this.formInline,
				...this.queryData,
			};
			this.$http
				.get(this.api.getLectureTrains, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.name,
								};
							});
						} else {
							this.link = res.data._links.create.href || '';
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			let data = {
				row: row || null,
				link: row ? row._links.update.href : this.link,
			};
			this.$refs.createView.init(data);
			this.isShowDetail = isShowDetail;
		},
		// 切换是否启用
		changeOpen(row) {
			this.$http
				.put(row._links.update.href, row)
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		onShow(row) {
			this.$refs.showList.showActivityVisible = true;
			this.$refs.showList.getShowList(row);
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
/deep/ .el-form {
	.el-date-editor {
		width: 360px;
		.el-range-separator {
			// padding: 0 5px;
			line-height: 25px;
			width: 25px;
		}
		.el-range-input {
			width: 42%;
		}
		.el-range__close-icon {
			margin-right: -5px;
		}
	}
}
</style>
